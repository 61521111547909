<template>
  <div class="page-support">
    <b-row>
      <b-col xl="4" lg="4" md="6">
        <div class="card" v-if="!showTicket.show">
          <div class="card-header pa-2">
            <h3>Create Ticket</h3>
          </div>
          <div class="card-body">
            <b-form @submit.prevent="onSendSubject">
              <div class="form-group">
                <label for="Subject">Subject</label>
                <b-form-select
                  v-model.trim="subject.subject"
                  required
                  id="Subject"
                  class="text-capitalize"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled>
                      -- Please select an subject --
                    </b-form-select-option>
                  </template>
                  <b-form-select-option
                    v-for="subject in Subject"
                    :key="`${subject.ticket_subject_id}${subject.ticket_subject_name}`"
                    :value="subject.ticket_subject_id"
                  >
                    {{ subject.ticket_subject_name.replace('_', ' ') }}
                  </b-form-select-option>
                </b-form-select>
              </div>
              <div class="form-group">
                <label for="des-subject">Description</label>
                <b-form-textarea
                  id="des-subject"
                  v-model="subject.content"
                  placeholder="Enter issue details about 150 characters."
                  rows="3"
                  max-rows="6"
                  maxlength="150"
                ></b-form-textarea>
              </div>
              <div class="form-group">
                <b-button
                  variant="none"
                  type="submit"
                  class="btn-info d-block align-items-center justify-content-center"
                  style="width: 100%"
                >
                  Send<i class="fas fa-sign-in-alt ml-1"></i>
                </b-button>
              </div>
            </b-form>
          </div>
        </div>
        <div class="box-detail" v-else>
          <Detail :ID="showTicket.id" :Subject="showTicket.subject" @close-detail="ResetDetail()" />
        </div>
      </b-col>
      <b-col xl="8" lg="8" md="6">
        <div class="card">
          <div class="card-header py-3 text-left">
            <h3 class="fz-md">Ticket List</h3>
          </div>
          <div class="card-body">
            <div class="table-reponsive">
              <b-table striped hover :items="List" :fields="fields">
                <template #cell(ticket_Status)="data">
                  <b-button
                    :variant="data.item.ticket_Status == 'Waiting' ? 'warning' : 'success'"
                    class="btn-sm font-weight-bold"
                    disabled
                  >
                    {{ data.item.ticket_Status }}
                  </b-button>
                </template>
                <template #cell(ticket_User)="data">
                  <b-button
                    variant="info"
                    class="btn-sm font-weight-bold"
                    @click="onShowDetail(data)"
                  >
                    Detail
                  </b-button>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Detail from '@/components/support/Detail.vue';

export default {
  components: { Detail },
  data() {
    return {
      subject: {
        content: '',
        subject: null,
      },
      fields: [
        {
          key: 'ticket_ID',
          sortable: false,
          label: 'ID',
          class: 'text-center text-middle',
        },
        {
          key: 'ticket_subject_name',
          sortable: false,
          label: 'Subject',
          class: 'text-center text-middle',
        },
        {
          key: 'ticket_Status',
          sortable: false,
          label: 'Status',
          class: 'text-center text-middle',
        },
        {
          key: 'ticket_Time',
          sortable: false,
          label: 'Date',
          class: 'text-center text-middle',
        },
        {
          key: 'ticket_User',
          sortable: false,
          label: 'Status',
          class: 'text-center text-middle',
        },
      ],
      showTicket: {
        id: null,
        subject: null,
        show: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      Subject: 'support/Subject',
      List: 'support/List',
    }),
  },
  methods: {
    async onSendSubject() {
      const token = await this.genarateCaptChaV3('create_account');
      if (!token) {
        this.$toastr.e('Too Fast! Please Wating Recaptcha.', 'Withdraw Failed');
      }
      this.subject.token = token;
      this.$store.dispatch('support/req_postSubject', this.subject);
    },
    ResetDetail() {
      this.showTicket = {
        id: null,
        subject: null,
        show: false,
      };
      this.$store.state.support.detail = [];
    },
    async onShowDetail(info) {
      await this.ResetDetail();
      const { item } = info;
      this.showTicket = {
        id: item.ticket_ID,
        subject: item.ticket_subject_id,
        show: true,
      };
    },
  },
  created() {
    this.$store.dispatch('support/req_SetUpTick');
    this.unsubscribe = this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case 'support/POST_SUBJECT_SUCCESS':
          this.subject = {
            content: '',
            subject: null,
          };
          break;

        default:
          break;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>

<style lang="scss">
.page-support {
  .card-body {
    .table-reponsive {
      width: 100%;
      overflow-x: auto;
    }
  }
  .box-write,
  .box-table {
    box-shadow: 0px 0px 10px 2px #00000038;
    background-color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    padding: 5px 0;
    margin-bottom: 15px;
    margin: 0 auto 15px;
    position: relative;
    width: calc(100% - 20px);
    min-height: 250px;
    .title {
      font-weight: 600;
      font-size: 20px;
      color: #2f394e;
      text-align: center;
      padding: 0 0 5px;
      border-bottom: 3px solid rgba($color: #000000, $alpha: 0.2);
      text-transform: uppercase;
      width: 100%;
    }
    form {
      width: 100%;
      padding: 10px 20px;
      .form-group {
        width: 100%;
        label {
          font-weight: 600;
          color: #6c757d;
          font-size: 16px;
          margin-bottom: 0px;
        }
        select,
        input {
          border: 0;
          border-radius: 0;
          border-bottom: 2px solid #6c757d;
          height: 40px;
          font-size: clamp(15px, 1vw, 16.2px);
          font-weight: 600;
          &:focus {
            box-shadow: none;
            border-bottom: 2px solid #097501;
          }
          &::placeholder {
            font-weight: 500;
            font-size: clamp(13px, 1vw, 15px);
          }
        }
        button {
          border: 1px solid #ccc;
          box-shadow: 0 2px 6px 0 #ccc;
          letter-spacing: 0.05rem;
          position: relative;
          background: #009750;
          color: #fff !important;
          min-width: 130px;
          width: 100%;
          height: 45px;
          overflow: hidden;
          transition: 0.3s ease-in-out;
          border-radius: 0.3rem;
          z-index: 1;
          &:hover,
          &:focus,
          &:focus-visible {
            box-shadow: 0 5px 18px rgba(0, 0, 0, 0.2), 0 10px 12px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
  }
  .box-detail {
    background-color: rgba(0, 0, 20, 0.5);
    border: 1px solid rgb(255 255 255);
    border-radius: 15px;
    color: #fff;
    margin-bottom: 20px;
  }
  .box-table {
    width: 100%;
    .box-reponsive {
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      table {
        width: 100%;
        .table th,
        .table td {
          min-width: 100px;
          white-space: pre;
        }
      }
    }
  }
}
</style>
